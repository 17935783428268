<template>
  <div id="site-settings">
    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      row-key="id"
      :data="list"
    >
      <el-table-column
        prop="category"
        :label="$t('CATEGORY')"
      >
        <template slot-scope="{row}">
          {{ $t(row.translationCode) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('STATUS')"
        width="100"
      >
        <template slot-scope="{row}">
          <div>
            <span :class="getStatusClass(row.status)">{{ $t(row.status) }}</span>
            <el-dropdown
              class="action-button"
              :hide-on-click="false"
              trigger="click"
            >
              <i class="custom-icon icon-edit mini center-in-div pointer"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item 
                  @click.native="goTo(row.redirectUrl)"
                >
                  {{ $t("EDIT") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
import { generalMixin } from "@/utils/general-mixin.js"
import cloneDeep from "lodash/cloneDeep"
import { getAllSiteSettings } from "@/api/site-settings"

const allSettingsList = [
  {
    type: "contact_us",
    translationCode: "CONTACT_US",
    redirectUrl: "/bo/site-settings/contact-us",
    status: "INACTIVE"
  },
  {
    type: "privacy_policy",
    translationCode: "PRIVACY_POLICY",
    redirectUrl: "/bo/site-settings/privacy-policy",
    status: "INACTIVE"
  },
  {
    type: "social_media",
    translationCode: "SOCIAL_MEDIA_LINKS",
    redirectUrl: "/bo/site-settings/social-media-links",
    status: "INACTIVE"
  },
]

export default {
  name: "SiteSettings",
  mixins: [generalMixin],
  components: {
  },
  data() {
    return {
      isLoading: false,
      list: cloneDeep(allSettingsList)
    }
  },
  methods: {
    // REDIRECTION
    getListDetail() {
      this.isLoading = true

      getAllSiteSettings()
      .then(res => {
        if (res.status != "ok") return

        const allSiteSettings = res.result

        const firstContactUs = allSiteSettings.find(
          (el) => el.type === "contact_us"
        )
        const firstPrivacyPolicy = allSiteSettings.find(
          (el) => el.type === "privacy_policy"
        )
        const firstSocialMedia = allSiteSettings.find(
          (el) => el.type === "social_media"
        )

        this.list = this.list.map((el) => {
          if (el.type === "contact_us" && firstContactUs != null) {
            el.status = firstContactUs.status
          }

          if (el.type === "privacy_policy" && firstPrivacyPolicy != null) {
            el.status = firstPrivacyPolicy.status
          }

          if (el.type === "social_media" && firstSocialMedia != null) {
            el.status = firstSocialMedia.status
          }
          return el
        })
      })
      .finally(() => {
        this.isLoading = false
      })

    }
  },
  mounted() {
    this.getListDetail()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/mixin/table.scss";
#site-settings {
  position: relative;
  @include newsily-bo-table;
  .action-button {
    float: right;
  }
}
</style>